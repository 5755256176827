.collectible-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  margin: 2rem;
  max-width: 300px;
  overflow: hidden;
  border: 1px solid #eaeaea;
}

.collectible-card__image {
  width: 100%;
  height: 200px;
  position: relative;
  max-height: 200px;
  padding: 1rem;
}

.collectible-card__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.collectible-card__content {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eaeaea;
}

.collectible-card__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: start;
}

.collectible-card__icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.collectible-card__link {
}
