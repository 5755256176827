
.social-sharing {
    background-color: transparent;
    border-radius: 0;
    flex-direction: row;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    z-index: 1;
    padding-left: 24px;
  }
  
  .social-sharing .share-text {
    align-items: flex-end;
    color: #888888;
    display: inline-flex;
    margin-right: 10px;
    text-transform: uppercase;
  }
  
  .light-gray {
    color: #888888;
  }

  .light-gray-over {
    color: #a3a3a3;
  }
  
  .social-sharing__link {
    color: #000000;
    display: inline-flex;
    flex-direction: row;
    font-size: 28px;
    margin: 0 5px 0 15px;
    cursor: pointer;
  }

  .social-sharing__link__over {
    color: white !important;
    padding-top: 30px;
  }
  
  .social-sharing__link:hover {
    color: #000000;
  }
  
  .social-sharing__link .social-sharing__link-copied {
    margin-left: 10px;
    padding-top: 4px;
    font-size: 14px;
    font-family: 'DM Mono' !important;
  }

  .social-sharing__link .social-sharing__link-copied-over {
    padding-top: 30px;
    color: white;
    margin-left: 10px;
    font-size: 14px;
    font-family: 'DM Mono' !important;
  }
  
  .social-sharing__title{
    display:inline-block;
    vertical-align:middle;
    padding-left:3px;
  }
  
  .fa-solid.fa-link-horizontal {
    content: "\e1cb";
  }
  
  .small-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  
  a:hover {
    text-decoration: none;
  }
   
  @media only screen and (max-width: 480px) {
    .social-sharing__title{
      display: none;
    }
  }

  @media screen and (min-width: 575px) and (max-width: 767px) {
    .social-sharing__link {
      margin: 0 6px 0 0 !important;
    }
    .social-sharing__link .social-sharing__link-copied {
      margin-left: 5px;
    }
  }

  @media screen and (max-width: 574px) {
    .social-sharing {
      padding-left: 0 !important;
    }
  }
   
  @media only screen and (max-width: 768px) {
    .social-sharing__link {
      margin: 0 15px 0 0;
    }
    .social-sharing__title{
      display: none;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width:1025px) {
    .small-text {
        font-size: 14px;
        line-height: 20px;
    }
    .social-sharing__title{
      display: none;
    }
  }
   
  @media screen and (max-width:1164px) {
    .social-sharing__title{
      display: none;
    }
  }
  
  @media screen and (min-width: 1025px) {
    .small-text {
        font-size: 14px;
        line-height: 20px;
    }
  }
  
  .visually-hidden{
    clip:rect(0 0 0 0);
    clip:rect(0, 0, 0, 0);
    overflow:hidden;
    position:absolute;
    height:1px;
    width:1px;
  }