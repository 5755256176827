.display-content {
  display: contents;
}
.info-experience{
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 30px 0;
  margin: 40px 0 60px 0;
}

.information-experience-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.information-experience-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
}
.information-experience-item svg {
  width: 102px;
  height: 102px;
}
.information-experience-item:last-child {
  padding-right: 30px;
}
.information-experience-item .information-experience-info {
  max-width: 310px;
  min-width: 200px;
}
.information-experience-item .information-experience-info {
  text-align: left;
}
.information-experience-info .item-title {
  margin: 0 0 5px;
}
.information-experience-item svg {
  height: 70px;
  fill: #000;
  margin: 10px 20px 0 0;
  width: 70px;
}
.information-experience-item svg.engaging-svg {
  left: 10px;
  position: relative;
}
.information-experience-item .information-experience-description {
  line-height: 21px;
  color: #a3a3a3;
}
.index-section--alt .information-experience-item svg {
  fill: white;
}
.information-experience-container p {
  margin:0;
}

.info-experience-header {
  padding-bottom: 30px;
}

@media screen and (min-width:769px) and (max-width:1279px) {
  .info-experience{
    padding: 0;
  }
  .information-experience-container {
    padding: 40px 20px;
    border-radius: 10px;
  }
  .information-experience-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .information-experience-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding-left: 30px;
  }
  .information-experience-item:last-child {
    padding-right: 0;
  }

  .information-experience-item svg {
    width: 102px;
    height: 102px;
    margin: 0;
    margin-bottom: 15px;
  } 
  .information-experience-item svg.engaging-svg {
    left: 20px;
  }

  .information-experience-item .information-experience-info {
    text-align: center;
    max-width: 240px;
    min-width: 150px;
    height: 120px;
  }
}

@media screen and (min-width:768px) and (max-width:1024px) {
  .information-experience-item {
    width: 50%;
  }
  .information-experience-item:last-child {
    padding-right: 0;
  }
}

 @media screen and (max-width:767px) {
  .information-experience-item {
    width: 100%;
    padding: 20px;
  }
  .information-experience-item:last-child {
    padding-right: 0;
  }
  .information-experience-item .information-experience-info {
    text-align: center;
  }
  .information-experience-item svg {
    margin: 0;
  }
  
}

@media screen and ( min-width: 1280px ) {
  .information-experience-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 25px;
  }
  .information-experience-list:last {
    padding-right: 30px;
  }
  .information-experience-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .information-experience-item:last-child {
    padding-right: 30px;
  }
  .information-experience-item .information-experience-info {
    text-align: center;
  }
  .information-experience-item svg {
    width: 102px;
    height: 102px;
    margin: 0 0 10px 0;
  }
  .information-experience-item svg.engaging-svg {
    left: 20px;
  }
  .information-experience-item .information-experience-info {
    max-width: 240px;
    min-width: 221px;
    height: 120px;
  }
}
