body {
    padding-top: 64px;
}

.header-logo {
  text-align: left;
  padding: 5px;
  display: block;
  width: 93px;
  height: 45px;
  cursor: pointer;
  margin-left: 20px;
}

.navbar-wrapper {
  background: #171717;
  height: auto;
  width: 100%;
  margin: 0;
  max-width: 100%;
  padding: 0;
}

.navbar-brand {
  margin: 0;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 64px;
}

#mdiv {
  width: 40px;
  height: 40px;
  z-index: 60;
  margin-right: 16px;
  padding-left: 5px;
}

.mdiv {
  height: 25px;
  width: 2px;
  margin-left: 12px;
  background-color: #fff;
  transform: rotate(45deg);
  z-index: 1;
}

.md {
  height: 25px;
  width: 2px;
  background-color: #fff;
  transform: rotate(90deg);
  z-index: 2;
}

.menu-bar {
  width: 25px;
  border-bottom: 2px solid white;
}

.hamburger-open {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-evenly;
  align-items: flex-end;
  border: 2px solid white;
  border-radius: 50%;
  padding: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-right: 16px;
}

.hamburger-menu-button {
  display: flex;
  align-items: center;
  border: none;
}

.hamburger-close {
  width: 40px;
  height: 40px;
  background-color: #000;
  margin-left: 14px;
  margin-right: 6px;
  z-index: 60;
  border: 2px solid white;
  border-radius: 50%;
  padding: 5px;
}

.hamburger-menu {
  transition: transform .2s;
  background: #000;
  color: #fff;
  z-index: 100;
  list-style: none;
  margin: 0 0 0 100%;
  position: fixed;
  border: 1px solid black;
  border-top: none;
  border-radius: 10px;
  width: 250px;
  padding-inline-start: 0;
}

.menu-item {
  cursor: pointer;
  z-index: 200;
  color: #fff;
  list-style: none;
}

.menu-item-link {
  text-decoration: none;
  display: block;
  width: auto!important;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 10px;
  color: #fff;
}

.menu-item-link:hover {
  background-color: #fff;
  color: #000;
}

.invert-menu-item:hover {
  color: #000;
  background: #fff;
}

.show-dropdown-menu {
  transform: translateX(-100%);
}

.header-overlay-2 {
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
}
