.footer {
  padding: 120px 0;
  background-color: #242424;
  flex-grow: 1;
}

.revel-logo {
  width: 154px;
}

.page-width {
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  height: 175px;
}

.footer-content__left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.footer-content__left ul {
  padding: 0;
  margin: 25px 0 27px;
  display: flex;
  align-items: flex-end;
  list-style: none;
}

.footer-content__left ul li {
  padding: 0;
  margin: 0 5px 0 0;
  display: flex;
  list-style: none;
}

.footer-li-header {
  color: #fff;
  padding-bottom: 15px;
}

.footer-content__right {
  display: flex;
  flex-direction: row;
  width: 50%;
  padding-left: 150px;
}

.copyright-text {
  color: #fff;
}

.footer-content__right ul {
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-content__right ul:first-child {
  padding-right: 113px;
}

.footer-content__right li {
  height: 37px;
  display: flex;
  align-items: flex-end;
  font-size: 18px;
}

.footer-content__right ul li a {
  list-style: none;
  text-decoration: none;
  color: #a3a3a3;
  font-size: 14px;
}
.footer i {
  color: #a3a3a3;
  padding-bottom: 5px;
  font-size: 13px;
}
.footer a.fa-a-brands {
  padding-left: 12px;
  padding-bottom: 2px;
}
.footer-padding {
  height: 73px;
  order: 2;
}
.fa.fa-brands.fa-tiktok {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.copyright-text-p {
  font-size: 18px;
  margin-top: 80px;
  color: #fff;
  font-family: "DM Mono";
}
.copyright-text {
  font-size: 14px;
  color: #a3a3a3;
  font-family: "DM Mono";
}
.copyright-text__mobile_p {
  font-size: 18px;
  margin-top: 80px;
  color: #fff;
  display: none;
  font-family: "DM Mono";
}
.copyright-text__mobile {
  font-size: 14px;
  color: #a3a3a3;
  display: none;
  margin: 25px 0 0 150px;
  font-family: "DM Mono";
}

.facebook-img {
  width: 41px;
}

.footer-flex-two i svg {
  width: 14px !important;
  height: 14px;
  margin-top: 6px;
  fill: #a3a3a3;
}

@media only screen and (max-width: 1044px) {
  .footer-content {
    flex-direction: column-reverse;
    height: auto;
    margin: 0 50px;
  }

  .footer-content__left ul {
    margin-top: 30px;
  }

  .footer-content__right {
    margin-top: 15px;
  }

  .footer-content__right ul {
    height: 175px;
    margin-bottom: 10px;
  }

  .copyright-text__mobile_p {
    display: block;
    margin-top: 60px;
    margin-bottom: 0;
    padding-left: 40px;
  }
  .copyright-text__mobile {
    display: block;
    margin: 0;
    padding-left: 40px;
  }
  .copyright-text-p {
    display: none;
  }

  .copyright-text {
    display: none;
    margin: 25px 190px 0;
    font-size: 1rem;
  }

  .footer-padding {
    display: none;
  }

  .page-width {
    padding: 0;
  }

  .footer-content__right {
    width: 100%;
    padding: 15px;
  }
  .footer-logo {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 60px 0;
  }

  .page-width {
    padding-right: 0;
  }

  .footer-content {
    margin: 0 50px;
  }

  .copyright-text-p {
    display: none;
  }
  .copyright-text__mobile {
    padding-right: 40px;
  }
  .footer-content__right ul {
    height: auto;
  }
  .footer-content__right ul:first-child {
    padding-right: 80px !important;
  }
}

@media only screen and (max-width: 300px) {
  .revel-logo {
    width: 180px;
  }
}
