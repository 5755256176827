.page-container {
  overflow: hidden;
}
.page-width {
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
}
.top-content {
  background-color: #000;
  height: auto;
  background: linear-gradient(#fff 0%, 0.5%, #000 100%);
}
.flex-direction-reverse {
  flex-direction: row-reverse;
}
.btn-secondary {
  margin-top: 30px !important;
}
.btn-tertiary {
  background-color: #dcff4b !important;
  border: 0px solid transparent !important;
  color: #000 !important;
  margin-top: 30px !important;
}
.btn:disabled, .btn[disabled] {
  cursor: default !important;
}
.center {
  text-align: center;
  margin-bottom: 45px;
}
.pt-115 {
  padding-top: 115px;
}
.top-content h4 {
  color: #a3a3a3;
  text-align: center;
  padding-top: 20px;
}
.brand-logo-white {
  margin: 0 auto;
  padding-top: 1.25rem;
  display: block;
  width: 15%;
  cursor: pointer;
}

.linear-bg {
  background: linear-gradient(#fff 0%, 0.5%, #000 100%);
}

.left-col {
  color: #ffffff;
  font-size: 40px;
  font-weight: 350;
  padding: 1rem;
  margin: 0 auto;
  width: fit-content;
  line-height: 130%;
  letter-spacing: 0.1rem;
}

.right-col {
  color: #dcff4b;
  font-size: 62px;
}

.right-col h1 {
  border-right: 3px solid white;
  font-weight: 300;
}

.EventTicket_right-col__3CgBc h4 {
  font-size: 2rem;
}

.gradient-text {
  background-image: linear-gradient(
    45deg,
    rgb(230, 228, 190) 0%,
    rgb(172, 168, 56) 50%,
    rgb(133, 131, 26) 100%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bottom-content {
  padding: 60px 0 45px 0;
  height: auto;
}

.m-300 {
  margin: 0 300px;
}

.pl-40 {
  padding-left: 40px !important;
}

.h1-italic {
  font-style: italic;
}
.h2-event-started{
  color: #a3a3a3;
  text-align: center;
  padding-top: 20px;
  font-size: 24px;
  font-weight: 400;
}
.host-img {
  width: 100%;
  height: fit-content;
  border-radius: 3px;
  animation: fade-in .6s ease 0s forwards;
}

.brand-logo-img {
  position: absolute;
  width: 25%;
  bottom: 45%;
  right: 5%;
  opacity: 0.15;
}

.favicon-img {
  background-color: white;
  padding: 2px;
  margin-top: 15px;
  margin-right: 1.5rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

footer {
  height: auto;
  background-color: white;
  padding: 3rem 0 1rem 0;
}

.socials-outer {
  border: 2px solid black;
  border-radius: 50%;
  width: fit-content;
  margin: 15px 10px;
}

.socials-logo {
  width: 2.5rem;
  margin: 10px 10px;
}

.EventTicket_right-col__1oZ-s {
  font-size: xx-large;
}

.event-btn-social .social-sharing .social-sharing__link i {
  color: white !important;
  padding-top: 20px;
}

@media only screen and (min-width: 600px) {
  .m-300 {
    margin: 0;
  }

  .brand-logo-white {
    width: 15%;
  }

  .left-col {
    margin-top: 2rem;
    font-size: 2.9rem;
    text-align: center;
  }

  .right-col h1 {
    margin: 0px;
    font-size: 4rem;
  }

  .socials-outer {
    margin: 20px 10px;
  }

  .EventTicket_right-col__1oZ-s {
    font-size: xx-large;
  }
}
@media screen and (min-width:769px) and (max-width:1279px) {
  .m-300 {
    margin: 0 200px !important;
  }
  .bottom-content {
    padding: 60px 40px 45px 40px;
  }
}

@media screen and (min-width:768px) and (max-width:1024px) {
  .m-300 {
    margin: 0 !important;
  }
  .bottom-content {
    padding: 60px 40px 45px 40px;
  }
}

@media screen and (max-width:767px) {
  .m-300 {
    margin: 60px 0 0 0 !important;
  }
  .host-img {
    width: 100%;
    height: fit-content;
    border-radius: 3px;
    animation: fade-in .6s ease 0s forwards;
  }
  .flex-direction-reverse {
    justify-content: center;
  }
  .left-col {
    text-align: center;
  }
  .bottom-content {
    padding: 0 40px;
    height: auto;
    box-shadow: none;
  }
  .pl-40 {
    padding-left: 0 !important;
  }
  .mt-40 {
    margin-top: 40px;
  }

}

@media screen and ( min-width: 1280px ) {
  .m-300 {
    margin: 0 200px !important;
  }
}
@media only screen and (max-width: 1044px) {
  .page-width {
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .page-width {
    padding-right: 0;
  }
}

.padding-left-24 {
  padding-left: 24px;
}

@media screen and (max-width: 574px) {
  .padding-left-24 {
    padding-left: 0 !important;
  }
}

.h5-countdown {
  color: #dcff4b;
  font-weight: 600;
  padding-top: 15px;
}

.h5-ticket-datetime {
  font-weight: 600;
}