:root {
  --darkestGray: #171717;
  --darkGray: #242424;
  --gray: #888888;
  --lightGray: #a3a3a3;
  --lightestGray: #f2f2f2;
  --h1FontFamily: DM Sans;
  --h1DesktopFontSize: 42px;
  --h1TabletFontSize: 42px;
  --h1MobileFontSize: 36px;
  --h1DesktopLineHeight: 55px;
  --h1TabletLineHeight: 55px;
  --h1MobileLineHeight: 44px;
  --h1FontWeight: bold;
  --h1Capitalize: none;
  --h2FontFamily: DM Sans;
  --h2DesktopFontSize: 40px;
  --h2TabletFontSize: 40px;
  --h2MobileFontSize: 28px;
  --h2DesktopLineHeight: 52px;
  --h2TabletLineHeight: 52px;
  --h2MobileLineHeight: 34px;
  --h2FontWeight: bold;
  --h2Capitalize: none;
  --h3FontFamily: DM Sans;
  --h3DesktopFontSize: 30px;
  --h3TabletFontSize: 30px;
  --h3MobileFontSize: 24px;
  --h3DesktopLineHeight: 38px;
  --h3TabletLineHeight: 38px;
  --h3MobileLineHeight: 31px;
  --h3FontWeight: bold;
  --h3Capitalize: none;
  --h4FontFamily: DM Sans;
  --h4DesktopFontSize: 25px;
  --h4TabletFontSize: 25px;
  --h4MobileFontSize: 20px;
  --h4DesktopLineHeight: 33px;
  --h4TabletLineHeight: 33px;
  --h4MobileLineHeight: 26px;
  --h4FontWeight: normal;
  --h4Capitalize: none;
  --h5FontFamily: DM Sans;
  --h5DesktopFontSize: 20px;
  --h5TabletFontSize: 20px;
  --h5MobileFontSize: 18px;
  --h5DesktopLineHeight: 36px;
  --h5TabletLineHeight: 36px;
  --h5MobileLineHeight: 24px;
  --h5FontWeight: normal;
  --h5Capitalize: none;
  --h6FontFamily: DM Sans;
  --h6DesktopFontSize: 18px;
  --h6TabletFontSize: 18px;
  --h6MobileFontSize: 16px;
  --h6DesktopLineHeight: 26px;
  --h6TabletLineHeight: 26px;
  --h6MobileLineHeight: 24px;
  --h6FontWeight: bold;
  --h6Capitalize: none;
  --paragraphFontFamily: DM Sans;
  --paragraphDesktopFontSize: 16px;
  --paragraphDesktopSubHeadingFontSize: 25px;
  --paragraphTabletFontSize: 16px;
  --paragraphMobileFontSize: 16px;
  --paragraphDesktopLineHeight: 24px;
  --paragraphTabletLineHeight: 24px;
  --paragraphMobileLineHeight: 24px;
  --paragraphFontWeight: normal;
  --smallTextFontFamily: DM Sans;
  --smallTextDesktopFontSize: 14px;
  --smallTextTabletFontSize: 14px;
  --smallTextMobileFontSize: 14px;
  --smallTextDesktopLineHeight: 20px;
  --smallTextTabletLineHeight: 20px;
  --smallTextMobileLineHeight: 20px;
  --smallTextFontWeight: normal;
  --unorderedListFontFamily: DM Sans;
  --unorderedListDesktopFontSize: 16px;
  --unorderedListTabletFontSize: 16px;
  --unorderedListMobileFontSize: 16px;
  --unorderedListDesktopLineHeight: 24px;
  --unorderedListTabletLineHeight: 24px;
  --unorderedListMobileLineHeight: 24px;
  --unorderedListFontWeight: normal;
  --unorderedListMarkerColor: ;
  --orderedListNumberFontFamily: DM Sans;
  --orderedListNumberDesktopFontSize: 38px;
  --orderedListNumberTabletFontSize: 38px;
  --orderedListNumberMobileFontSize: 38px;
  --orderedListNumberDesktopLineHeight: 33px;
  --orderedListNumberTabletLineHeight: 33px;
  --orderedListNumberMobileLineHeight: 33px;
  --orderedListNumberFontWeight: bold;
  --orderedListFontFamily: DM Sans;
  --orderedListDesktopFontSize: 25px;
  --orderedListTabletFontSize: 25px;
  --orderedListMobileFontSize: 25px;
  --orderedListDesktopLineHeight: 33px;
  --orderedListTabletLineHeight: 33px;
  --orderedListMobileLineHeight: 33px;
  --orderedListFontWeight: normal;
  --primaryButtonBackgroundColor: #ffffff;
  --primaryButtonBorderColor: #000000;
  --primaryButtonBorderRadius: 30px;
  --primaryButtonBorderWidth: 2px;
  --primaryButtonColor: #000000;
  --primaryButtonFontFamily: DM Sans;
  --primaryButtonFontWeight: normal;
  --primaryButtonHoverBackgroundColor: #000000;
  --primaryButtonHoverBorderColor: #000000;
  --primaryButtonHoverBorderWidth: 2px;
  --primaryButtonHoverColor: #ffffff;
  --primaryButtonHoverFontWeight: normal;
  --primaryButtonFocusBackgroundColor: #000000;
  --primaryButtonFocusBorderColor: #000000;
  --primaryButtonFocusBorderWidth: 2px;
  --primaryButtonFocusColor: #ffffff;
  --primaryButtonFocusFontWeight: bold;
  --primaryButtonDisabledBackgroundColor: rgba(255, 255, 255, .2);
  --primaryButtonDisabledBorderColor: rgba(0, 0, 0, .2);
  --primaryButtonDisabledBorderWidth: 2px;
  --primaryButtonDisabledColor: rgba(0, 0, 0, .2);
  --primaryButtonDisabledFontWeight: normal;
  --secondaryButtonBackgroundColor: #dcff4b;
  --secondaryButtonBorderColor: transparent;
  --secondaryButtonBorderRadius: 30px;
  --secondaryButtonBorderWidth: 0px;
  --secondaryButtonColor: #000000;
  --secondaryButtonFontFamily: DM Sans;
  --secondaryButtonFontWeight: normal;
  --secondaryButtonHoverBackgroundColor: #dcff4b;
  --secondaryButtonHoverBorderColor: transparent;
  --secondaryButtonHoverBorderWidth: 0px;
  --secondaryButtonHoverColor: #000000;
  --secondaryButtonHoverFontWeight: bold;
  --secondaryButtonFocusBackgroundColor: #dcff4b;
  --secondaryButtonFocusBorderColor: transparent;
  --secondaryButtonFocusBorderWidth: 0px;
  --secondaryButtonFocusColor: #000000;
  --secondaryButtonFocusFontWeight: bold;
  --secondaryButtonDisabledBackgroundColor: rgba(220, 255, 75, .2);
  --secondaryButtonDisabledBorderColor: rgba(0, 0, 0, .2);
  --secondaryButtonDisabledBorderWidth: 0px;
  --secondaryButtonDisabledColor: #000000;
  --secondaryButtonDisabledFontWeight: normal;
  --primaryButtonAltBackgroundColor: #000000;
  --primaryButtonAltBorderColor: #ffffff;
  --primaryButtonAltBorderRadius: px;
  --primaryButtonAltBorderWidth: 2px;
  --primaryButtonAltColor: #ffffff;
  --primaryButtonAltHoverBackgroundColor: #ffffff;
  --primaryButtonAltHoverBorderColor: #ffffff;
  --primaryButtonAltHoverBorderWidth: px;
  --primaryButtonAltHoverColor: #000000;
  --primaryButtonAltFocusBackgroundColor: #ffffff;
  --primaryButtonAltFocusBorderColor: #ffffff;
  --primaryButtonAltFocusBorderWidth: px;
  --primaryButtonAltFocusColor: #000000;
  --primaryButtonAltDisabledBackgroundColor: rgba(0, 0, 0, .2);
  --primaryButtonAltDisabledBorderColor: rgba(255, 255, 255, .2);
  --primaryButtonAltDisabledBorderWidth: px;
  --primaryButtonAltDisabledColor: rgba(255, 255, 255, .2);
  --secondaryButtonAltBackgroundColor: #dcff4b;
  --secondaryButtonAltBorderColor: transparent;
  --secondaryButtonAltBorderRadius: px;
  --secondaryButtonAltBorderWidth: 0px;
  --secondaryButtonAltColor: #000000;
  --secondaryButtonAltHoverBackgroundColor: #dcff4b;
  --secondaryButtonAltHoverBorderColor: transparent;
  --secondaryButtonAltHoverBorderWidth: px;
  --secondaryButtonAltHoverColor: #000000;
  --secondaryButtonAltFocusBackgroundColor: #dcff4b;
  --secondaryButtonAltFocusBorderColor: transparent;
  --secondaryButtonAltFocusBorderWidth: px;
  --secondaryButtonAltFocusColor: #000000;
  --secondaryButtonAltDisabledBackgroundColor: rgba(220, 255, 75, .2);
  --secondaryButtonAltDisabledBorderColor: rgba(0, 0, 0, .2);
  --secondaryButtonAltDisabledBorderWidth: px;
  --secondaryButtonAltDisabledColor: #000000;
  --largeButtonDesktopFontSize: 24px;
  --largeButtonTabletFontSize: 24px;
  --largeButtonMobileFontSize: 24px;
  --largeButtonDesktopLineHeight: 24px;
  --largeButtonTabletLineHeight: 24px;
  --largeButtonMobileLineHeight: 24px;
  --largeButtonPadding: 16px 24px 16px 24px;
  --mediumButtonDesktopFontSize: 18px;
  --mediumButtonTabletFontSize: 18px;
  --mediumButtonMobileFontSize: 18px;
  --mediumButtonDesktopLineHeight: 18px;
  --mediumButtonTabletLineHeight: 18px;
  --mediumButtonMobileLineHeight: 18px;
  --mediumButtonPadding: 12px 20px 12px 20px;
  --smallButtonDesktopFontSize: 14px;
  --smallButtonTabletFontSize: 14px;
  --smallButtonMobileFontSize: 14px;
  --smallButtonDesktopLineHeight: 14px;
  --smallButtonTabletLineHeight: 14px;
  --smallButtonMobileLineHeight: 14px;
  --smallButtonPadding: 8px 16px 8px 16px;
  --textInputBackgroundColor: transparent;
  --textInputColor: #a3a3a3;
  --textInputHoverColor: #888888;
  --textInputFocusColor: #000000;
  --textInputValidColor: #000000;
  --textInputErrorColor: #e70202;
  --textInputDisabledColor: #a3a3a3;
  --textInputBorderColor: #a3a3a3;
  --textInputHoverBorderColor: #888888;
  --textInputFocusBorderColor: #000000;
  --textInputValidBorderColor: #000000;
  --textInputErrorBorderColor: #e70202;
  --textInputDisabledBorderColor: #888888;
  --textInputAltBackgroundColor: transparent;
  --textInputAltColor: #888888;
  --textInputAltHoverColor: #a3a3a3;
  --textInputAltFocusColor: #ffffff;
  --textInputAltValidColor: #ffffff;
  --textInputAltErrorColor: #e70202;
  --textInputAltDisabledColor: #888888;
  --textInputAltBorderColor: #888888;
  --textInputAltHoverBorderColor: #a3a3a3;
  --textInputAltFocusBorderColor: #ffffff;
  --textInputAltValidBorderColor: #ffffff;
  --textInputAltErrorBorderColor: #e70202;
  --textInputAltDisabledBorderColor: #888888;
  --textInputFontFamily: DM Mono;
  --textInputFontWeight: normal;
  --textInputFontSize: 18px;
  --textInputLineHeight: 38px;
  --linkFontFamily: DM Sans;
  --linkFontWeight: normal;
  --linkHoverFontWeight: normal;
  --linkFocusFontWeight: bold;
  --linkDisabledFontWeight: normal;
  --linkDesktopFontSize: 18px;
  --linkTabletFontSize: 18px;
  --linkMobileFontSize: 18px;
  --linkDesktopLineHeight: 24px;
  --linkTabletLineHeight: 24px;
  --linkMobileLineHeight: 24px;
  --linkColor: #888888;
  --linkHoverColor: #888888;
  --linkFocusColor: #888888;
  --linkDisabledColor: rgba(136, 136, 136, .45);
  --linkAltColor: #a3a3a3;
  --linkAltHoverColor: #a3a3a3;
  --linkAltFocusColor: #a3a3a3;
  --linkAltDisabledColor: rgba(163, 163, 163, .45);
  --linkDisabledOpacity: .45;
  --linkUnderline: false;
  --linkHoverUnderline: true;
  --linkFocusUnderline: true;
  --linkDisabledUnderline: false;
  --checkboxFontFamily: DM Mono;
  --checkboxFontWeight: normal;
  --checkboxFontSize: 26px;
  --checkboxLineHeight: 27px;
  --checkboxBorderWidth: 2px;
  --checkboxBackgroundColor: transparent;
  --checkboxBorderColor: #a3a3a3;
  --checkboxColor: transparent;
  --checkboxHoverBackgroundColor: transparent;
  --checkboxHoverBorderColor: #888888;
  --checkboxHoverColor: transparent;
  --checkboxCheckedBackgroundColor: #000000;
  --checkboxCheckedBorderColor: #000000;
  --checkboxCheckedColor: #ffffff;
  --checkboxCheckedHoverBackgroundColor: #888888;
  --checkboxCheckedHoverBorderColor: #888888;
  --checkboxCheckedHoverColor: #ffffff;
  --checkboxDisabledBackgroundColor: transparent;
  --checkboxDisabledBorderColor: #a3a3a3;
  --checkboxDisabledColor: transparent;
  --checkboxCheckedDisabledBackgroundColor: #d6d6d6;
  --checkboxCheckedDisabledBorderColor: #d6d6d6;
  --checkboxCheckedDisabledColor: #ffffff;
  --checkboxAltBackgroundColor: transparent;
  --checkboxAltBorderColor: #888888;
  --checkboxAltColor: transparent;
  --checkboxAltHoverBackgroundColor: transparent;
  --checkboxAltHoverBorderColor: #a3a3a3;
  --checkboxAltHoverColor: transparent;
  --checkboxAltCheckedBackgroundColor: #ffffff;
  --checkboxAltCheckedBorderColor: #ffffff;
  --checkboxAltCheckedColor: #000000;
  --checkboxAltCheckedHoverBackgroundColor: #a3a3a3;
  --checkboxAltCheckedHoverBorderColor: #a3a3a3;
  --checkboxAltCheckedHoverColor: #ffffff;
  --checkboxAltDisabledBackgroundColor: transparent;
  --checkboxAltDisabledBorderColor: #888888;
  --checkboxAltDisabledColor: transparent;
  --checkboxAltCheckedDisabledBackgroundColor: #3d3d3d;
  --checkboxAltCheckedDisabledBorderColor: #3d3d3d;
  --checkboxAltCheckedDisabledColor: #888888;
  --radioButtonFontFamily: DM Mono;
  --radioButtonFontWeight: normal;
  --radioButtonFontSize: 26px;
  --radioButtonLineHeight: 27px;
  --radioButtonBorderWidth: 2px;
  --radioButtonBackgroundColor: transparent;
  --radioButtonBorderColor: #a3a3a3;
  --radioButtonColor: transparent;
  --radioButtonHoverBackgroundColor: transparent;
  --radioButtonHoverBorderColor: #888888;
  --radioButtonHoverColor: transparent;
  --radioButtonCheckedBackgroundColor: #ffffff;
  --radioButtonCheckedBorderColor: #000000;
  --radioButtonCheckedColor: #000000;
  --radioButtonCheckedHoverBackgroundColor: #ffffff;
  --radioButtonCheckedHoverBorderColor: #888888;
  --radioButtonCheckedHoverColor: #888888;
  --radioButtonDisabledBackgroundColor: transparent;
  --radioButtonDisabledBorderColor: #a3a3a3;
  --radioButtonDisabledColor: transparent;
  --radioButtonCheckedDisabledBackgroundColor: #d6d6d6;
  --radioButtonCheckedDisabledBorderColor: #d6d6d6;
  --radioButtonCheckedDisabledColor: #ffffff;
  --radioButtonAltBackgroundColor: transparent;
  --radioButtonAltBorderColor: #888888;
  --radioButtonAltColor: transparent;
  --radioButtonAltHoverBackgroundColor: transparent;
  --radioButtonAltHoverBorderColor: #a3a3a3;
  --radioButtonAltHoverColor: transparent;
  --radioButtonAltCheckedBackgroundColor: #000000;
  --radioButtonAltCheckedBorderColor: #ffffff;
  --radioButtonAltCheckedColor: #ffffff;
  --radioButtonAltCheckedHoverBackgroundColor: #000000;
  --radioButtonAltCheckedHoverBorderColor: #a3a3a3;
  --radioButtonAltCheckedHoverColor: #a3a3a3;
  --radioButtonAltDisabledBackgroundColor: transparent;
  --radioButtonAltDisabledBorderColor: #888888;
  --radioButtonAltDisabledColor: transparent;
  --radioButtonAltCheckedDisabledBackgroundColor: #3d3d3d;
  --radioButtonAltCheckedDisabledBorderColor: #3d3d3d;
  --radioButtonAltCheckedDisabledColor: #888888;
  --typeBaseSize: 16px;
  --buttonRadius: 30px;
  --iconWeight: 5px;
  --iconLinecaps: miter;
  --colorBtnPrimaryBgTransition: background .15s ease;
  --colorCartDot: #000000;
  --colorCartDotText: #ffffff;
  --colorLink: #000000;
  --colorTextBody: #000000;
  --colorSalePrice: #000000;
  --colorSaleTag: #ff0000;
  --colorSaleTagText: #000000;
  --colorBody: #ffffff;
  --colorBodyDim: #f2f2f2;
  --colorInputBg: #ffffff;
  --colorInputText: #000000;
  --colorAlt: #000000;
  --colorAltText: #ffffff;
  --colorNav: #ffffff;
  --colorNavText: #fff;
  --colorAnnouncement: #ffffff;
  --colorAnnouncementText: #000000;
  --colorNewsletter: #000000;
  --colorNewsletterText: #fff;
  --colorHeroText: #ffffff;
  --colorSmallImageBg: #ffffff;
  --colorLargeImageBg: #000000;
  --colorImageOverlay: #000000;
  --colorImageOverlayOpacity: .2;
  --colorImageOverlayTextShadow: .2;
  --colorDrawers: #ffffff;
  --colorDrawersDim: #f2f2f2;
  --colorDrawerText: #000000;
  --colorDrawerTextDarken: #000000
}

html,body {
  background-color: #fff;
  background-color: var(--colorBody);
  color: #000;
  color: var(--colorTextBody)
}

body {
  display: block;
  font-family: DM Sans;
  font-family: var(--paragraphFontFamily);
  font-size: 16px;
  font-size: var(--paragraphMobileFontSize);
  font-weight: 400;
  font-weight: var(--paragraphFontWeight);
  line-height: 24px;
  line-height: var(--paragraphMobileLineHeight);
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
  margin: 0 0 10px
}

.h1 a,.h2 a,.h3 a,.h4 a,.h5 a,.h6 a,h1 a,h2 a,h3 a,h4 a,h5 a,h6 a {
  text-decoration: none;
  font-weight: inherit
}

h1,.h1 {
  font-family: DM Sans;
  font-family: var(--h1FontFamily);
  font-size: 36px;
  font-size: var(--h1MobileFontSize);
  font-weight: 700;
  font-weight: var(--h1FontWeight);
  line-height: 44px;
  line-height: var(--h1MobileLineHeight);
  text-transform: none;
  text-transform: var(--h1Capitalize);
  word-break: break-word
}

h2,.h2 {
  font-family: DM Sans;
  font-family: var(--h2FontFamily);
  font-size: 28px;
  font-size: var(--h2MobileFontSize);
  font-weight: 700;
  font-weight: var(--h2FontWeight);
  line-height: 34px;
  line-height: var(--h2MobileLineHeight);
  text-transform: none;
  text-transform: var(--h2Capitalize);
  word-break: break-word
}

h3,.h3 {
  font-family: DM Sans;
  font-family: var(--h3FontFamily);
  font-size: 24px;
  font-size: var(--h3MobileFontSize);
  font-weight: 700;
  font-weight: var(--h3FontWeight);
  line-height: 31px;
  line-height: var(--h3MobileLineHeight);
  text-transform: none;
  text-transform: var(--h3Capitalize);
  word-break: break-word
}

h4,.h4 {
  font-family: DM Sans;
  font-family: var(--h4FontFamily);
  font-size: 20px;
  font-size: var(--h4MobileFontSize);
  font-weight: 400;
  font-weight: var(--h4FontWeight);
  line-height: 26px;
  line-height: var(--h4MobileLineHeight);
  text-transform: none;
  text-transform: var(--h4Capitalize);
  word-break: break-word
}

h5,.h5 {
  font-family: DM Sans;
  font-family: var(--h5FontFamily);
  font-size: 18px;
  font-size: var(--h5MobileFontSize);
  font-weight: 400;
  font-weight: var(--h5FontWeight);
  line-height: 24px;
  line-height: var(--h5MobileLineHeight);
  text-transform: none;
  text-transform: var(--h5Capitalize);
  word-break: break-word
}

h6,.h6 {
  font-family: DM Sans;
  font-family: var(--h6FontFamily);
  font-size: 16px;
  font-size: var(--h6MobileFontSize);
  font-weight: 700;
  font-weight: var(--h6FontWeight);
  line-height: 24px;
  line-height: var(--h6MobileLineHeight);
  text-transform: none;
  text-transform: var(--h6Capitalize);
  word-break: break-word
}

p {
  font-family: DM Sans;
  font-family: var(--paragraphFontFamily);
  font-size: 16px;
  font-size: var(--paragraphMobileFontSize);
  font-weight: 400;
  font-weight: var(--paragraphFontWeight);
  line-height: 24px;
  line-height: var(--paragraphMobileLineHeight)
}

.small-text {
  font-family: DM Sans!important;
  font-family: var(--smallTextFontFamily)!important;
  font-size: 14px!important;
  font-size: var(--smallTextMobileFontSize)!important;
  font-weight: 400!important;
  font-weight: var(--smallTextFontWeight)!important;
  line-height: 20px!important;
  line-height: var(--smallTextMobileLineHeight)!important
}

button {
  color: currentColor;
  font-family: DM Sans;
  font-family: var(--primaryButtonFontFamily);
  font-size: 24px;
  font-size: var(--largeButtonMobileFontSize);
  line-height: 24px;
  line-height: var(--largeButtonMobileLineHeight);
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%
}

.btn {
  background-color: #fff;
  background-color: var(--primaryButtonBackgroundColor);
  border: 2px solid #000000;
  border: var(--primaryButtonBorderWidth) solid var(--primaryButtonBorderColor);
  border-radius: 30px;
  border-radius: var(--primaryButtonBorderRadius);
  color: #000;
  color: var(--primaryButtonColor);
  font-family: DM Sans;
  font-family: var(--primaryButtonFontFamily);
  font-weight: 400;
  font-weight: var(--primaryButtonFontWeight);
  font-size: 24px;
  font-size: var(--largeButtonMobileFontSize);
  line-height: 24px;
  line-height: var(--largeButtonMobileLineHeight);
  padding: 16px 24px;
  padding: var(--largeButtonPadding);
  display: inline-block;
  margin: 0;
  width: auto;
  min-width: 90px;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none
}

.btn .fa-arrow-up,.btn .fa-arrow-right,.btn .fa-arrow-down,.btn .fa-arrow-left {
  display: none
}

.btn .fa-arrow-up {
  padding: 0 0 0 7px
}

.btn:hover .fa-arrow-up,.btn:focus .fa-arrow-up,.btn:disabled .fa-arrow-up,.btn[disabled] .fa-arrow-up,.btn:hover .fa-arrow-right,.btn:focus .fa-arrow-right,.btn:disabled .fa-arrow-right,.btn[disabled] .fa-arrow-right,.btn:hover .fa-arrow-down,.btn:focus .fa-arrow-down,.btn:disabled .fa-arrow-down,.btn[disabled] .fa-arrow-down,.btn:hover .fa-arrow-left,.btn:focus .fa-arrow-left,.btn:disabled .fa-arrow-left,.btn[disabled] .fa-arrow-left {
  display: inline-block
}

.btn:hover {
  background-color: #000;
  background-color: var(--primaryButtonHoverBackgroundColor);
  border: 2px solid #000000;
  border: var(--primaryButtonHoverBorderWidth) solid var(--primaryButtonHoverBorderColor);
  color: #fff;
  color: var(--primaryButtonHoverColor);
  font-weight: 400;
  font-weight: var(--primaryButtonHoverFontWeight)
}

.btn:focus,.btn:active {
  background-color: #000;
  background-color: var(--primaryButtonFocusBackgroundColor);
  border: 2px solid #000000;
  border: var(--primaryButtonFocusBorderWidth) solid var(--primaryButtonFocusBorderColor);
  color: #fff;
  color: var(--primaryButtonFocusColor);
  font-weight: 700;
  font-weight: var(--primaryButtonFocusFontWeight)
}

.btn:disabled,.btn[disabled] {
  background-color: #fff3;
  background-color: var(--primaryButtonDisabledBackgroundColor);
  border: 2px solid rgba(0,0,0,.2);
  border: var(--primaryButtonDisabledBorderWidth) solid var(--primaryButtonDisabledBorderColor);
  color: #0003;
  color: var(--primaryButtonDisabledColor);
  font-weight: 400;
  font-weight: var(--primaryButtonDisabledFontWeight);
  cursor: default;
}

.btn--secondary,.btn--tertiary {
  background-color: #dcff4b;
  background-color: var(--secondaryButtonBackgroundColor);
  border: 0px solid transparent;
  border: var(--secondaryButtonBorderWidth) solid var(--secondaryButtonBorderColor);
  border-radius: 30px;
  border-radius: var(--secondaryButtonBorderRadius);
  color: #000;
  color: var(--secondaryButtonColor);
  font-family: DM Sans;
  font-family: var(--secondaryButtonFontFamily);
  font-weight: 400;
  font-weight: var(--secondaryButtonFontWeight)
}

.btn--secondary:hover,.btn--tertiary:hover {
  background-color: #dcff4b;
  background-color: var(--secondaryButtonHoverBackgroundColor);
  border: 0px solid transparent;
  border: var(--secondaryButtonHoverBorderWidth) solid var(--secondaryButtonHoverBorderColor);
  color: #000;
  color: var(--secondaryButtonHoverColor);
  font-weight: 700;
  font-weight: var(--secondaryButtonHoverFontWeight)
}

.btn--secondary:focus,.btn--secondary:active,.btn--tertiary:focus,.btn--tertiary:active {
  background-color: #dcff4b;
  background-color: var(--secondaryButtonFocusBackgroundColor);
  border: 0px solid transparent;
  border: var(--secondaryButtonFocusBorderWidth) solid var(--secondaryButtonFocusBorderColor);
  color: #000;
  color: var(--secondaryButtonFocusColor);
  font-weight: 700;
  font-weight: var(--secondaryButtonFocusFontWeight)
}

.btn--secondary:disabled,.btn--secondary[disabled],.btn--tertiary:disabled,.btn--tertiary[disabled] {
  background-color: #dcff4b33;
  background-color: var(--secondaryButtonDisabledBackgroundColor);
  border: 0px solid rgba(0,0,0,.2);
  border: var(--secondaryButtonDisabledBorderWidth) solid var(--secondaryButtonDisabledBorderColor);
  color: #000;
  color: var(--secondaryButtonDisabledColor);
  font-weight: 400;
  font-weight: var(--secondaryButtonDisabledFontWeight)
}

.index-section--alt .btn {
  background-color: #000;
  background-color: var(--primaryButtonAltBackgroundColor);
  border: 2px solid #ffffff;
  border: var(--primaryButtonAltBorderWidth) solid var(--primaryButtonAltBorderColor);
  color: #fff;
  color: var(--primaryButtonAltColor)
}

.index-section--alt .btn:hover {
  background-color: #fff;
  background-color: var(--primaryButtonAltHoverBackgroundColor);
  border: 2px solid #ffffff;
  border: var(--primaryButtonAltBorderWidth) solid var(--primaryButtonAltHoverBorderColor);
  color: #000;
  color: var(--primaryButtonAltHoverColor)
}

.index-section--alt .btn:focus,.index-section--alt .btn:active {
  background-color: #fff;
  background-color: var(--primaryButtonAltFocusBackgroundColor);
  border: 2px solid #ffffff;
  border: var(--primaryButtonAltBorderWidth) solid var(--primaryButtonAltFocusBorderColor);
  color: #000;
  color: var(--primaryButtonAltFocusColor)
}

.index-section--alt .btn:disabled,.index-section--alt .btn[disabled] {
  background-color: #0003;
  background-color: var(--primaryButtonAltDisabledBackgroundColor);
  border: 2px solid rgba(255,255,255,.2);
  border: var(--primaryButtonAltBorderWidth) solid var(--primaryButtonAltDisabledBorderColor);
  color: #fff3;
  color: var(--primaryButtonAltDisabledColor)
}

.index-section--alt .btn--secondary,.index-section--alt .btn--tertiary {
  background-color: #dcff4b;
  background-color: var(--secondaryButtonAltBackgroundColor);
  border: 0px solid transparent;
  border: var(--secondaryButtonAltBorderWidth) solid var(--secondaryButtonAltBorderColor);
  color: #000;
  color: var(--secondaryButtonAltColor)
}

.index-section--alt .btn--secondary:hover,.index-section--alt .btn--tertiary:hover {
  background-color: #dcff4b;
  background-color: var(--secondaryButtonAltHoverBackgroundColor);
  border: 0px solid transparent;
  border: var(--secondaryButtonAltBorderWidth) solid var(--secondaryButtonAltHoverBorderColor);
  color: #000;
  color: var(--secondaryButtonAltHoverColor)
}

.index-section--alt .btn--secondary:focus,.index-section--alt .btn--secondary:active,.index-section--alt .btn--tertiary:focus,.index-section--alt .btn--tertiary:active {
  background-color: #dcff4b;
  background-color: var(--secondaryButtonAltFocusBackgroundColor);
  border: 0px solid transparent;
  border: var(--secondaryButtonAltBorderWidth) solid var(--secondaryButtonAltFocusBorderColor);
  color: #000;
  color: var(--secondaryButtonAltFocusColor)
}

.index-section--alt .btn--secondary:disabled,.index-section--alt .btn--secondary[disabled],.index-section--alt .btn--tertiary:disabled,.index-section--alt .btn--tertiary[disabled] {
  background-color: #dcff4b33;
  background-color: var(--secondaryButtonAltDisabledBackgroundColor);
  border: 0px solid rgba(0,0,0,.2);
  border: var(--secondaryButtonAltBorderWidth) solid var(--secondaryButtonAltDisabledBorderColor);
  color: #000;
  color: var(--secondaryButtonAltDisabledColor)
}

.btn--large {
  font-size: 24px;
  font-size: var(--largeButtonMobileFontSize);
  line-height: 24px;
  line-height: var(--largeButtonMobileLineHeight);
  padding: 16px 24px;
  padding: var(--largeButtonPadding)
}

.btn--medium {
  font-size: 18px;
  font-size: var(--mediumButtonMobileFontSize);
  line-height: 18px;
  line-height: var(--mediumButtonMobileLineHeight);
  padding: 12px 20px;
  padding: var(--mediumButtonPadding)
}

.btn--small {
  font-size: 14px;
  font-size: var(--smallButtonMobileFontSize);
  line-height: 14px;
  line-height: var(--smallButtonMobileLineHeight);
  padding: 8px 16px;
  padding: var(--smallButtonPadding)
}

.text-link,a {
  color: inherit;
  text-decoration: none;
  background: transparent
}

.text-link,.rte a:not(.btn) {
  color: #888;
  color: var(--linkColor);
  font-family: DM Sans;
  font-family: var(--linkFontFamily);
  font-size: 18px;
  font-size: var(--linkMobileFontSize);
  font-weight: 400;
  font-weight: var(--linkFontWeight);
  line-height: 24px;
  line-height: var(--linkMobileLineHeight)
}

.text-link:hover,.rte a:not(.btn):hover {
  color: #888;
  color: var(--linkHoverColor);
  font-weight: 400;
  font-weight: var(--linkHoverFontWeight);
  text-decoration: underline
}

.text-link:focus,.text-link:active,.rte a:not(.btn):focus,.rte a:not(.btn):active {
  color: #888;
  color: var(--linkFocusColor);
  font-weight: 700;
  font-weight: var(--linkFocusFontWeight);
  text-decoration: underline
}

.text-link:disabled,.text-link[disabled],.rte a:not(.btn):disabled,.rte a:not(.btn)[disabled] {
  color: #88888873;
  color: var(--linkDisabledColor);
  font-weight: 400;
  font-weight: var(--linkDisabledFontWeight)
}

ul.ul-styled {
  font-family: DM Sans;
  font-family: var(--unorderedListFontFamily);
  font-size: 16px;
  font-size: var(--unorderedListMobileFontSize);
  line-height: 24px;
  line-height: var(--unorderedListMobileLineHeight);
  font-weight: 400;
  font-weight: var(--unorderedListFontWeight);
  list-style: none;
  margin: 10px 0 10px 30px
}

ul.ul-styled li:before {
  border: 1px solid;
  border: 1px solid var(--unorderedListMarkerColor);
  content: "";
  display: inline-block;
  height: 1px;
  width: 12px;
  margin: 0 10px 5px 0
}

ul.ul-styled.small-text li:before {
  margin: 0 10px 3px 0
}

ol:not(.flickity-page-dots) {
  list-style: decimal
}

ol.ol-styled {
  counter-reset: ol-counter;
  font-family: DM Sans;
  font-family: var(--orderedListFontFamily);
  font-size: 25px;
  font-size: var(--orderedListMobileFontSize);
  line-height: 33px;
  line-height: var(--orderedListMobileLineHeight);
  font-weight: 400;
  font-weight: var(--orderedListFontWeight);
  list-style: none;
  margin: 0 0 20px 40px
}

ol.ol-styled>li {
  counter-increment: ol-counter;
  margin: 0 0 .5rem;
  position: relative
}

ol.ol-styled>li:before {
  content: counter(ol-counter);
  font-family: DM Sans;
  font-family: var(--orderedListNumberFontFamily);
  font-size: 38px;
  font-size: var(--orderedListNumberMobileFontSize);
  font-weight: 700;
  font-weight: var(--orderedListNumberFontWeight);
  height: 38px;
  height: var(--orderedListNumberMobileFontSize);
  left: -48px;
  left: calc(-1 * var(--orderedListNumberMobileFontSize) - 10px);
  line-height: 33px;
  line-height: var(--orderedListNumberMobileLineHeight);
  position: absolute;
  top: 0;
  text-align: center;
  width: 38px;
  width: var(--orderedListNumberMobileFontSize)
}

select {
  -webkit-appearance: none;
  appearance: none;
  background-position: 100%;
  background-image: url(//cdn.shopify.com/s/files/1/0609/7141/7812/t/4/assets/chevron-down-a3a3a3.svg?v=3589816410838726378);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 15px
}

select:hover,select.error:hover {
  background-image: url(//cdn.shopify.com/s/files/1/0609/7141/7812/t/4/assets/chevron-down-888888.svg?v=16577038702584244073)
}

select:focus,select:active,select.error:focus,select.error:active {
  background-image: url(//cdn.shopify.com/s/files/1/0609/7141/7812/t/4/assets/chevron-down-000000.svg?v=13326921199852095958)
}

select.error {
  background-image: url(//cdn.shopify.com/s/files/1/0609/7141/7812/t/4/assets/chevron-down-e70202.svg?v=5092806845099272797)
}

.index-section--alt select {
  background-image: url(//cdn.shopify.com/s/files/1/0609/7141/7812/t/4/assets/chevron-down-888888.svg?v=16577038702584244073)
}

.index-section--alt select:hover,.index-section--alt select.error:hover {
  background-image: url(//cdn.shopify.com/s/files/1/0609/7141/7812/t/4/assets/chevron-down-a3a3a3.svg?v=3589816410838726378)
}

.index-section--alt select:focus,.index-section--alt select:active,.index-section--alt select.error:focus,.index-section--alt select.error:active {
  background-image: url(//cdn.shopify.com/s/files/1/0609/7141/7812/t/4/assets/chevron-down-ffffff.svg?v=14079583010911349432)
}

.index-section--alt select.error {
  background-image: url(//cdn.shopify.com/s/files/1/0609/7141/7812/t/4/assets/chevron-down-e70202.svg?v=5092806845099272797)
}

input,textarea,select {
  background-color: transparent;
  background-color: var(--textInputBackgroundColor);
  border: 0;
  border-bottom: 2px solid #a3a3a3;
  border-bottom: 2px solid var(--textInputBorderColor);
  color: #a3a3a3;
  color: var(--textInputColor);
  font-family: DM Mono;
  font-family: var(--textInputFontFamily);
  font-size: 18px;
  font-size: var(--textInputFontSize);
  max-width: 100%;
  padding: 8px 0;
  border-radius: 0
}

input.error,textarea.error,select.error {
  color: #e70202;
  color: var(--textInputErrorColor);
  border-color: #e70202;
  border-color: var(--textInputErrorBorderColor)
}

input:hover,textarea:hover,select:hover {
  border-color: #888;
  border-color: var(--textInputHoverBorderColor);
  color: #888;
  color: var(--textInputHoverColor)
}

input:focus,textarea:focus,select:focus {
  border-color: #000;
  border-color: var(--textInputFocusBorderColor);
  color: #000;
  color: var(--textInputFocusColor)
}

.index-section--alt input,.index-section--alt textarea,.index-section--alt select {
  border: 0;
  border-bottom: 2px solid #888888;
  border-bottom: 2px solid var(--textInputAltBorderColor);
  color: #888;
  color: var(--textInputAltColor)
}

.index-section--alt input.error,.index-section--alt textarea.error,.index-section--alt select.error {
  color: #e70202;
  color: var(--textInputErrorColor);
  border-color: #e70202;
  border-color: var(--textInputErrorBorderColor)
}

.index-section--alt input:hover,.index-section--alt textarea:hover,.index-section--alt select:hover {
  border-color: #a3a3a3;
  border-color: var(--textInputAltHoverBorderColor);
  color: #a3a3a3;
  color: var(--textInputAltHoverColor)
}

.index-section--alt input:focus,.index-section--alt textarea:focus,.index-section--alt select:focus {
  border-color: #fff;
  border-color: var(--textInputAltFocusBorderColor);
  color: #fff;
  color: var(--textInputAltFocusColor)
}

input[type=checkbox],input[type=radio] {
  margin: 0 10px 0 0;
  padding: 0;
  width: auto
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox
}

input[type=radio] {
  -webkit-appearance: radio;
  -moz-appearance: radio
}

textarea {
  height: 100px;
  min-height: 100px
}

label.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: DM Mono;
  font-family: var(--checkboxFontFamily);
  font-size: 26px;
  font-size: var(--checkboxFontSize);
  font-weight: 400;
  font-weight: var(--checkboxFontWeight);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

label.custom-checkbox input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0
}

label.custom-checkbox .checkbox {
  background-color: transparent;
  background-color: var(--checkboxBackgroundColor);
  border: var(--checkboxBorderWidth) solid var(--checkboxBorderColor);
  border: 2px solid #a3a3a3;
  border-radius: 5px;
  color: transparent;
  color: var(--checkboxColor);
  font-size: 20px;
  height: 25px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 25px
}

label.custom-checkbox .checkbox:after {
  content: "";
  position: absolute;
  display: none
}

label.custom-checkbox input~.checkbox:hover {
  background-color: transparent;
  background-color: var(--checkboxHoverBackgroundColor);
  border: 2px solid #888888;
  border: var(--checkboxBorderWidth) solid var(--checkboxHoverBorderColor);
  color: transparent;
  color: var(--checkboxHoverColor)
}

label.custom-checkbox input:checked~.checkbox {
  background-color: #000;
  background-color: var(--checkboxCheckedBackgroundColor);
  border: 2px solid #000000;
  border: var(--checkboxBorderWidth) solid var(--checkboxCheckedBorderColor);
  color: #fff;
  color: var(--checkboxCheckedColor)
}

label.custom-checkbox input:checked~.checkbox:hover {
  background-color: #888;
  background-color: var(--checkboxCheckedHoverBackgroundColor);
  border: 2px solid #888888;
  border: var(--checkboxBorderWidth) solid var(--checkboxCheckedHoverBorderColor);
  color: #fff;
  color: var(--checkboxCheckedHoverColor)
}

label.custom-checkbox input:disabled~.checkbox {
  background-color: transparent;
  background-color: var(--checkboxDisabledBackgroundColor);
  border: 2px solid #a3a3a3;
  border: var(--checkboxBorderWidth) solid var(--checkboxDisabledBorderColor);
  color: transparent;
  color: var(--checkboxDisabledColor)
}

label.custom-checkbox input:disabled:checked~.checkbox {
  background-color: #d6d6d6;
  background-color: var(--checkboxCheckedDisabledBackgroundColor);
  border: 2px solid #d6d6d6;
  border: var(--checkboxBorderWidth) solid var(--checkboxCheckedDisabledBorderColor);
  color: ;
  color: var(--checkboxCheckedDisabledHoverColor)
}

label.custom-checkbox input:checked~.checkbox:after {
  display: block
}

label.custom-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: DM Mono;
  font-family: var(--radioButtonFontFamily);
  font-size: 26px;
  font-size: var(--radioButtonFontSize);
  font-weight: 400;
  font-weight: var(--radioButtonFontWeight);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

label.custom-radio input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0
}

label.custom-radio .radio {
  background-color: transparent;
  background-color: var(--radioButtonBackgroundColor);
  border: 2px solid #a3a3a3;
  border: var(--radioButtonBorderWidth) solid var(--radioButtonBorderColor);
  border-radius: 50%;
  color: transparent;
  color: var(--radioButtonColor);
  font-size: 16px;
  height: 25px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 25px
}

label.custom-radio .radio i {
  display: block;
  position: relative;
  top: 3.5px
}

label.custom-radio .radio:after {
  content: "";
  position: absolute;
  display: none
}

label.custom-radio input~.radio:hover {
  background-color: transparent;
  background-color: var(--radioButtonHoverBackgroundColor);
  border: 2px solid #888888;
  border: var(--radioButtonBorderWidth) solid var(--radioButtonHoverBorderColor);
  color: transparent;
  color: var(--radioButtonHoverColor)
}

label.custom-radio input:checked~.radio {
  background-color: #fff;
  background-color: var(--radioButtonCheckedBackgroundColor);
  border: 2px solid #000000;
  border: var(--radioButtonBorderWidth) solid var(--radioButtonCheckedBorderColor);
  color: #000;
  color: var(--radioButtonCheckedColor)
}

label.custom-radio input:checked~.radio:hover {
  background-color: #fff;
  background-color: var(--radioButtonCheckedHoverBackgroundColor);
  border: 2px solid #888888;
  border: var(--radioButtonBorderWidth) solid var(--radioButtonCheckedHoverBorderColor);
  color: #888;
  color: var(--radioButtonCheckedHoverColor)
}

label.custom-radio input:disabled~.radio {
  background-color: transparent;
  background-color: var(--radioButtonDisabledBackgroundColor);
  border: 2px solid #a3a3a3;
  border: var(--radioButtonBorderWidth) solid var(--radioButtonDisabledBorderColor);
  color: transparent;
  color: var(--radioButtonDisabledColor)
}

label.custom-radio input:disabled:checked~.radio {
  background-color: #d6d6d6;
  background-color: var(--radioButtonCheckedDisabledBackgroundColor);
  border: 2px solid #d6d6d6;
  border: var(--radioButtonBorderWidth) solid var(--radioButtonCheckedDisabledBorderColor);
  color: #fff;
  color: var(--radioButtonCheckedDisabledColor)
}

label.custom-radio input:checked~.radio:after {
  display: block
}

.index-section--alt label.custom-checkbox .checkbox {
  background-color: transparent;
  background-color: var(--checkboxAltBackgroundColor);
  border: 2px solid #888888;
  border: var(--checkboxBorderWidth) solid var(--checkboxAltBorderColor);
  color: transparent;
  color: var(--checkboxAltColor)
}

.index-section--alt label.custom-checkbox input~.checkbox:hover {
  background-color: transparent;
  background-color: var(--checkboxAltHoverBackgroundColor);
  border: 2px solid #a3a3a3;
  border: var(--checkboxBorderWidth) solid var(--checkboxAltHoverBorderColor);
  color: transparent;
  color: var(--checkboxAltHoverColor)
}

.index-section--alt label.custom-checkbox input:checked~.checkbox {
  background-color: #fff;
  background-color: var(--checkboxAltCheckedBackgroundColor);
  border: 2px solid #ffffff;
  border: var(--checkboxBorderWidth) solid var(--checkboxAltCheckedBorderColor);
  color: #000;
  color: var(--checkboxAltCheckedColor)
}

.index-section--alt label.custom-checkbox input:checked~.checkbox:hover {
  background-color: #a3a3a3;
  background-color: var(--checkboxAltCheckedHoverBackgroundColor);
  border: 2px solid #a3a3a3;
  border: var(--checkboxBorderWidth) solid var(--checkboxAltCheckedHoverBorderColor);
  color: #fff;
  color: var(--checkboxAltCheckedHoverColor)
}

.index-section--alt label.custom-checkbox input:disabled~.checkbox {
  background-color: transparent;
  background-color: var(--checkboxAltDisabledBackgroundColor);
  border: 2px solid #888888;
  border: var(--checkboxBorderWidth) solid var(--checkboxAltDisabledBorderColor);
  color: transparent;
  color: var(--checkboxAltDisabledColor)
}

.index-section--alt label.custom-checkbox input:disabled:checked~.checkbox {
  background-color: ;
  background-color: var(--checkboxAltCheckedDisabledHoverBackgroundColor);
  border: 2px solid;
  border: var(--checkboxBorderWidth) solid var(--checkboxAltCheckedDisabledHoverBorderColor);
  color: ;
  color: var(--checkboxAltCheckedDisabledHoverColor)
}

.index-section--alt label.custom-radio .radio {
  background-color: transparent;
  background-color: var(--radioButtonAltBackgroundColor);
  border: 2px solid #888888;
  border: var(--radioButtonBorderWidth) solid var(--radioButtonAltBorderColor);
  color: transparent;
  color: var(--radioButtonAltColor)
}

.index-section--alt label.custom-radio input~.radio:hover {
  background-color: transparent;
  background-color: var(--radioButtonAltHoverBackgroundColor);
  border: 2px solid #a3a3a3;
  border: var(--radioButtonBorderWidth) solid var(--radioButtonAltHoverBorderColor);
  color: transparent;
  color: var(--radioButtonAltHoverColor)
}

.index-section--alt label.custom-radio input:checked~.radio {
  background-color: #000;
  background-color: var(--radioButtonAltCheckedBackgroundColor);
  border: 2px solid #ffffff;
  border: var(--radioButtonBorderWidth) solid var(--radioButtonAltCheckedBorderColor);
  color: #fff;
  color: var(--radioButtonAltCheckedColor)
}

.index-section--alt label.custom-radio input:checked~.radio:hover {
  background-color: #000;
  background-color: var(--radioButtonAltCheckedHoverBackgroundColor);
  border: 2px solid #a3a3a3;
  border: var(--radioButtonBorderWidth) solid var(--radioButtonAltCheckedHoverBorderColor);
  color: #a3a3a3;
  color: var(--radioButtonAltCheckedHoverColor)
}

.index-section--alt label.custom-radio input:disabled~.radio {
  background-color: transparent;
  background-color: var(--radioButtonAltDisabledBackgroundColor);
  border: 2px solid #888888;
  border: var(--radioButtonBorderWidth) solid var(--radioButtonAltDisabledBorderColor);
  color: transparent;
  color: var(--radioButtonAltDisabledColor)
}

.index-section--alt label.custom-radio input:disabled:checked~.radio {
  background-color: ;
  background-color: var(--radioButtonAltCheckedDisabledHoverBackgroundColor);
  border: 2px solid;
  border: var(--radioButtonBorderWidth) solid var(--radioButtonAltCheckedDisabledHoverBorderColor);
  color: ;
  color: var(--radioButtonAltCheckedDisabledHoverColor)
}

@media screen and (min-width: 769px) and (max-width:1025px) {
  body {
      font-size:16px;
      font-size: var(--paragraphTabletFontSize);
      line-height: 24px;
      line-height: var(--paragraphTabletLineHeight)
  }

  .h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
      margin: 0 0 20px
  }

  h1,.h1 {
      font-size: 42px;
      font-size: var(--h1TabletFontSize);
      line-height: 55px;
      line-height: var(--h1TabletLineHeight)
  }

  h2,.h2 {
      font-size: 40px;
      font-size: var(--h2TabletFontSize);
      line-height: 52px;
      line-height: var(--h2TabletLineHeight)
  }

  h3,.h3 {
      font-size: 30px;
      font-size: var(--h3TabletFontSize);
      line-height: 38px;
      line-height: var(--h3TabletLineHeight)
  }

  h4,.h4 {
      font-size: 25px;
      font-size: var(--h4TabletFontSize);
      line-height: 33px;
      line-height: var(--h4TabletLineHeight)
  }

  h5,.h5 {
      font-size: 20px;
      font-size: var(--h5TabletFontSize);
      line-height: 36px;
      line-height: var(--h5TabletLineHeight)
  }

  h6,.h6 {
      font-size: 18px;
      font-size: var(--h6TabletFontSize);
      line-height: 26px;
      line-height: var(--h6TabletLineHeight)
  }

  p {
      font-size: 16px;
      font-size: var(--paragraphTabletFontSize);
      line-height: 24px;
      line-height: var(--paragraphTabletLineHeight)
  }

  .small-text {
      font-size: 14px!important;
      font-size: var(--smallTextTabletFontSize)!important;
      line-height: 20px!important;
      line-height: var(--smallTextTabletLineHeight)!important
  }

  .text-link {
      font-size: 18px;
      font-size: var(--linkTabletFontSize);
      line-height: 24px;
      line-height: var(--linkTabletLineHeight)
  }

  .btn,.btn--large {
      font-size: 24px;
      font-size: var(--largeButtonTabletFontSize);
      line-height: 24px;
      line-height: var(--largeButtonTabletLineHeight)
  }

  .btn--medium {
      font-size: 18px;
      font-size: var(--mediumButtonTabletFontSize);
      line-height: 18px;
      line-height: var(--mediumButtonTabletLineHeight)
  }

  .btn--small {
      font-size: 14px;
      font-size: var(--smallButtonTabletFontSize);
      line-height: 14px;
      line-height: var(--smallButtonTabletLineHeight)
  }

  ul.ul-styled {
      font-size: 16px;
      font-size: var(--unorderedListTabletFontSize);
      line-height: 24px;
      line-height: var(--unorderedListTabletLineHeight)
  }

  ol.ol-styled {
      font-size: 25px;
      font-size: var(--orderedListTabletFontSize);
      line-height: 33px;
      line-height: var(--orderedListTabletLineHeight)
  }

  ol.ol-styled>li:before {
      font-size: 38px;
      font-size: var(--orderedListNumberTabletFontSize);
      height: 38px;
      height: var(--orderedListNumberTabletFontSize);
      left: -48px;
      left: calc(-1 * var(--orderedListNumberTabletFontSize) - 10px);
      line-height: 33px;
      line-height: var(--orderedListNumberTabletLineHeight);
      width: 38px;
      width: var(--orderedListNumberTabletFontSize)
  }
}

@media screen and (min-width: 1025px) {
  body {
      font-size:16px;
      font-size: var(--paragraphDesktopFontSize);
      line-height: 24px;
      line-height: var(--paragraphDesktopLineHeight)
  }

  .h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
      margin: 0 0 20px
  }

  h1,.h1 {
      font-size: 42px;
      font-size: var(--h1DesktopFontSize);
      line-height: 55px;
      line-height: var(--h1DesktopLineHeight)
  }

  h2,.h2 {
      font-size: 40px;
      font-size: var(--h2DesktopFontSize);
      line-height: 52px;
      line-height: var(--h2DesktopLineHeight)
  }

  h3,.h3 {
      font-size: 30px;
      font-size: var(--h3DesktopFontSize);
      line-height: 38px;
      line-height: var(--h3DesktopLineHeight)
  }

  h4,.h4 {
      font-size: 25px;
      font-size: var(--h4DesktopFontSize);
      line-height: 33px;
      line-height: var(--h4DesktopLineHeight)
  }

  h5,.h5 {
      font-size: 20px;
      font-size: var(--h5DesktopFontSize);
      line-height: 36px;
      line-height: var(--h5DesktopLineHeight)
  }

  h6,.h6 {
      font-size: 18px;
      font-size: var(--h6DesktopFontSize);
      line-height: 26px;
      line-height: var(--h6DesktopLineHeight)
  }

  p {
      font-size: 16px;
      font-size: var(--paragraphDesktopFontSize);
      line-height: 24px;
      line-height: var(--paragraphDesktopLineHeight)
  }

  .small-text {
      font-size: 14px!important;
      font-size: var(--smallTextDesktopFontSize)!important;
      line-height: 20px!important;
      line-height: var(--smallTextDesktopLineHeight)!important
  }

  .text-link {
      font-size: 18px;
      font-size: var(--linkDesktopFontSize);
      line-height: 24px;
      line-height: var(--linkDesktopLineHeight)
  }

  .btn,.btn--large {
      font-size: 24px;
      font-size: var(--largeButtonDesktopFontSize);
      line-height: 24px;
      line-height: var(--largeButtonDesktopLineHeight)
  }

  .btn--medium {
      font-size: 18px;
      font-size: var(--mediumButtonDesktopFontSize);
      line-height: 18px;
      line-height: var(--mediumButtonDesktopLineHeight)
  }

  .btn--small {
      font-size: 14px;
      font-size: var(--smallButtonDesktopFontSize);
      line-height: 14px;
      line-height: var(--smallButtonDesktopLineHeight)
  }

  ul.ul-styled {
      font-size: 16px;
      font-size: var(--unorderedListDesktopFontSize);
      line-height: 24px;
      line-height: var(--unorderedListDesktopLineHeight)
  }

  ol.ol-styled {
      font-size: 25px;
      font-size: var(--orderedListDesktopFontSize);
      line-height: 33px;
      line-height: var(--orderedListDesktopLineHeight)
  }

  ol.ol-styled>li:before {
      font-size: 38px;
      font-size: var(--orderedListNumberDesktopFontSize);
      height: 38px;
      height: var(--orderedListNumberDesktopFontSize);
      left: -48px;
      left: calc(-1 * var(--orderedListNumberDesktopFontSize) - 10px);
      line-height: 33px;
      line-height: var(--orderedListNumberDesktopLineHeight);
      width: 38px;
      width: var(--orderedListNumberDesktopFontSize)
  }
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */

.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  left: 50%;
  margin-left: -4em;
  height: 8em;
  width: 8em;
}

.result-block-container .result-block {
  opacity: 1;
}

.result-block-container {
  min-height: 100px;
}

#app {
  min-height: 95vh;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}