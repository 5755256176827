.back-to-top {
  position: fixed;
  bottom: 6em;
  right: 0px;
  text-decoration: none;
  fill: #fff;
  font-size: 16px;
  padding: 14px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 25px;
  z-index: 60000;
  background-color: #888;
  padding: 13px 18px;
}
.back-to-top i svg {
  vertical-align: middle;
  width: 14px;
  height: 14px;
}
.back-to-top:hover {
  text-decoration: none;
}
